/*Code coming from spinkit*/
$spinner-margin: 40px auto !default;
$size: 40px !default;
$spinner-color: #398db5 !default;

.sk-folding-cube {
  $cubeCount: 4;
  $animationDuration: 2.4s;
  $delayRange: $animationDuration/2;

  margin: $spinner-margin;
  width: $size;
  height: $size;
  transform: rotateZ(45deg);
  z-index: 2000;

  .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    transform: scale(1.1);
  }

  .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $spinner-color;
    animation: sk-foldCubeAngle $animationDuration infinite linear both;
    transform-origin: 100% 100%;
  }

  // Rotation / angle
  @for $i from 2 through $cubeCount {
    .sk-cube#{$i} {
      transform: scale(1.1) rotateZ(90deg * ($i - 1));
    }
  }

  @for $i from 2 through $cubeCount {
    .sk-cube#{$i}:before {
      animation-delay: $delayRange / $cubeCount * ($i - 1);
    }
  }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  } 90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

.page-loader {
  position: absolute;
  top: calc(50vh - #{$size / 2});
  left: calc(50vw - #{$size / 2} );
}

body.sidebar-fixed:not(.sidebar-hidden) .page-loader {
  left: calc(50vw + #{$sidebar-width / 2 - $size / 2} );
}

body.sidebar-fixed.sidebar-minimized:not(.sidebar-hidden) .page-loader {
  left: calc(50vw + #{$sidebar-minimized-width / 2 - $size / 2} );
}

body.loading .app-body body{
  -moz-filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  filter: gray; /* IE6-9 */
  filter: grayscale(100%);
  -webkit-transition: .2s -webkit-filter linear;
  -moz-transition: .2s -moz-filter linear;
  -ms-transition: .2s -ms-filter linear;
  -o-transition: .2s -o-filter linear;
  transition: .2s filter linear, .2s -webkit-filter linear, .2s -moz-filter linear;
}
