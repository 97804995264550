.btn-secondary{
  --bs-btn-color: white;
  --bs-btn-hover-color: white;
  --bs-btn-active-color: white;
}

:root {
  // rgb-version of $primary / default in bootstrap 4
  --bs-link-color-rgb: 0, 176, 240;
}
