// Here you can add other styles

.text-main {
  color: #0376ba;
}

.sidebar {
  .sidebar-nav {
    width: auto;
  }
  .nav {
    width: auto;
  }
}

@include media-breakpoint-up(lg) {
  .sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link {
    padding-left: 1.5rem;
  }
}

.react-bs-table-sizePerPage-dropdown {
  margin-bottom: 1rem;
  display: block;
}

.react-bs-table table td {
  white-space: normal !important;
  padding: 0.55rem 0.25rem;
  vertical-align: middle;
}

.react-bs-table table th {
  position: relative;
  padding: 0.25rem 0.25rem 0.25rem 1.2rem;

  .fa-sort, .fa-sort-asc, .fa-sort-desc {
    position: absolute;
    margin-top: 3px !important;
    left: 0;
  }
}

.react-bs-table .react-bs-select-all {
  /* There should be a better solution for this. I am just calculating
   it based on our initial paddings 1.2 - 0.25 = .95 */
  margin-left: -0.95rem;
}

.form-group > label {
  width: 100%;
  margin-bottom: 0;
}

.dform-group > label ~ .invalid-feedback {
  display: block;
}

.card-header-actions {
  float: right;
  margin-right: -.25rem;
}

.card-header-action {
  padding: 0 .25rem;
  color: #73818f;
}

.card-header-action:hover {
  color: #23282c;
  text-decoration: none;
}

#app {
  /* IE11 does not understand min-height in flex */
  height: 100vh;
}

.app-body {
  flex: 1 0 auto;

 .main {
    width: 100%;
 }
}

.sidebar .sidebar-nav {
  flex: 1 1 0%;
}

input[type="color"] {
  padding: 4px;
  height: 36px;
}

.btn[disabled], input[disabled], .disabled {
  cursor: not-allowed;
}

.add-to-wallet-button {
  width: 206px;
  height: 64px;
}
.add-to-other-wallet{
  border-radius: 0.8em;
  line-height: 44px;
}
