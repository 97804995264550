//css to keep some of the old bootstrap4 classes running - this way we can save some time on updating html
.form-group {
  margin-bottom: 1rem;
}

.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col {
  padding-left: 5px;
  padding-right: 5px;
}

label {
  margin-bottom: 0.5rem;
}
